import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'

const GlobalPanelContext = createContext()

export const GlobalPanelProvider = ({ children }) => {
  const [currentPanels, setCurrentPanels] = useState([])

  const value = useMemo(() => {
    return {
      currentPanels,
      topPanel: currentPanels[currentPanels.length - 1],
      addPanel: (key) => {
        setCurrentPanels((p) => [...p, key])
      },
      removePanel: (key) => {
        setCurrentPanels((panels) => panels.filter((panel) => panel !== key))
      },
    }
  }, [currentPanels])

  return (
    <GlobalPanelContext.Provider value={value}>
      {children}
    </GlobalPanelContext.Provider>
  )
}

const initialState = {
  isOpen: false,
  isUnder: false,
  shouldFocus: false,
}

function reducer(state, action) {
  switch (action.type) {
    case 'open':
      return {
        ...state,
        isOpen: true,
        isUnder: false,
        shouldFocus: true,
        ...(action.props ? { ...action.props } : {}),
      }

    case 'close':
      return {
        ...initialState,
      }

    case 'under':
      return {
        ...state,
        isUnder: true,
      }

    case 'over':
      return {
        ...state,
        isUnder: false,
      }

    default:
      break
  }
}

export default function usePanel(key, options) {
  const initialIsOpen = !!options?.initialIsOpen

  const { topPanel, addPanel, removePanel } = useContext(GlobalPanelContext)

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    isOpen: initialIsOpen,
  })

  useEffect(() => {
    if (state.isOpen && !state.isUnder && topPanel !== key) {
      return dispatch({ type: 'under' })
    }
    if (state.isOpen && state.isUnder && topPanel === key) {
      return dispatch({ type: 'over' })
    }
  }, [key, state.isOpen, state.isUnder, topPanel])

  const returnVal = useMemo(() => {
    const close = () => {
      removePanel(key)
      dispatch({ type: 'close' })
    }

    const open = (props) => {
      const action = { type: 'open' }

      if (!props || !props.constructor || props.constructor.name === 'Object') {
        action.props = props
      }
      addPanel(key)
      dispatch(action)
    }

    return [{ ...state, close }, open, close]
  }, [addPanel, key, removePanel, state])

  return returnVal
}
