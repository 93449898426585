import axios from 'axios'
import { useState, useCallback, useMemo } from 'react'
import Qs from 'qs'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import axiosRetry from 'axios-retry'
import useDebounce from '@/hooks/useDebounce'
import { useSession } from '@/util/session'

const axiosInstance = axios.create({
  paramsSerializer: {
    serialize: function (params) {
      return Qs.stringify(params, { arrayFormat: 'brackets' })
    },
  },
})

axiosRetry(axiosInstance, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    const method = error?.config?.method
    const status = error?.response?.status
    return method && method.toLowerCase() === 'get' && status === 401
  },
})

export const defaultQueryFn = async ({ queryKey }) => {
  // console.log({ rest })
  const [url, queryParams = {}] = queryKey
  const { data } = await axiosInstance.get(url, { params: queryParams })
  return data
}

export const createQueryFn =
  (settings = {}) =>
  async ({ queryKey }) => {
    const [url, queryParams = {}] = queryKey
    const { data } = await axiosInstance.get(url, {
      params: queryParams,
      ...settings,
    })
    return data
  }

export function useGetInstance(url, { requestSettings, ...settings } = {}) {
  const { status } = useSession()
  const queryKey = useMemo(
    () => (settings.queryParams ? [url, settings.queryParams] : [url]),
    [url, settings.queryParams]
  )

  const queryFn = useMemo(
    () => createQueryFn(requestSettings),
    [requestSettings]
  )

  const { data, ...rest } = useQuery(queryKey, queryFn, {
    ...settings,
    enabled:
      status === 'authenticated' &&
      !!url &&
      (!settings.hasOwnProperty('enabled') || settings.enabled),
  })

  return {
    queryKey,
    ...rest,
    ...(data || {}),
  }
}

// for getting raw body instead of json data
export function useGetData(url, settings = {}) {
  const { status } = useSession()
  const queryKey = useMemo(
    () => (settings.queryParams ? [url, settings.queryParams] : [url]),
    [url, settings.queryParams]
  )
  const query = useQuery(queryKey, defaultQueryFn, {
    ...settings,
    enabled:
      status === 'authenticated' &&
      !!url &&
      (!settings.hasOwnProperty('enabled') || settings.enabled),
  })

  return {
    queryKey,
    ...query,
  }
}

export function useGetCollection(
  url,
  { initialPage = 1, initialPerPage = 250, queryParams = {}, ...settings } = {}
) {
  const { status } = useSession()
  const [page, setPage] = useState(initialPage)
  const [perPage, setPerPageState] = useState(initialPerPage)

  const setPerPage = useCallback((val) => {
    setPerPageState(val)
    setPage(1)
  }, [])

  const queryKey = useMemo(
    () => [url, { page, per_page: perPage, ...queryParams }],
    [url, page, perPage, queryParams]
  )

  const { data: responseData, ...rest } = useQuery(queryKey, defaultQueryFn, {
    ...settings,
    enabled:
      status === 'authenticated' &&
      !!url &&
      (!settings.hasOwnProperty('enabled') || settings.enabled),
  })

  const { pagination: responsePagination, ...data } = responseData || {}

  const pagination = useMemo(() => {
    if (!responsePagination) {
      return responsePagination
    }

    return {
      ...responsePagination,
      setPage,
      setPerPage,
      nextPage: responsePagination.nextPage
        ? () => setPage(responsePagination.nextPage)
        : null,
      prevPage: responsePagination.prevPage
        ? () => setPage(responsePagination.prevPage)
        : null,
    }
  }, [responsePagination, setPerPage])

  return {
    queryKey,
    ...rest,
    ...data,
    pagination,
  }
}

export function useSearchCollection(
  url,
  {
    queryParams: specifiedQueryParams = {},
    normalizeData,
    initialSearchQuery = '',
    ...settings
  } = {}
) {
  const [searchQuery, setQ] = useState(initialSearchQuery)

  const debouncedSearchQuery = useDebounce(searchQuery)

  const { pagination: _, ...query } = useGetCollection(url, {
    queryParams: {
      ...specifiedQueryParams,
      searchQuery: debouncedSearchQuery,
    },
    enabled: !!url && !!debouncedSearchQuery,
    keepPreviousData: true,
    select: normalizeData
      ? (json) => ({
          ...json,
          data: normalizeData(json.data),
        })
      : null,
    ...settings,
  })

  return { ...query, setQ }
}

// export function useAutocompleteModelQuery({
//   initialQuery = '',
//   normalizeData,
//   getQueryParamsFromQuery = (query) => ({ where: [`q=@${query}`] }),
//   querySettings = {},
//   ...settings
// } = {}) {
//   const [q, setQ] = useState(initialQuery)

//   const debouncedQ = useDebounce(q)

//   const query = useModelQuery({
//     queryParams: {
//       per_page: 999,
//       ...getQueryParamsFromQuery(debouncedQ),
//     },
//     querySettings: {
//       enabled: !!debouncedQ,
//       keepPreviousData: true,
//       ...querySettings,
//       select: normalizeData
//         ? (json) => ({
//             ...json,
//             data: normalizeData(json.data),
//           })
//         : null,
//     },
//     acceptHeader: acceptTypes.autocomplete,
//     ...settings,
//   })

//   return { ...query, setQ }
// }

export function useGetAllCollection(url, { queryParams, ...settings } = {}) {
  const { status } = useSession()

  const queryKey = useMemo(
    () => [url, { ...(queryParams ? queryParams : {}), loadAllPages: true }],
    [url, queryParams]
  )

  const { data, ...rest } = useQuery(queryKey, defaultQueryFn, {
    ...settings,
    enabled:
      status === 'authenticated' &&
      !!url &&
      (!settings.hasOwnProperty('enabled') || settings.enabled),
  })

  return {
    queryKey,
    ...rest,
    ...(data || {}),
  }
}

// const infiniteCollectionQueryFn = async ({ queryKey, pageParam = 1 }) => {
//   // console.log({ pageParam })
//   const [url, { per_page = 5, page = pageParam, ...queryParams } = {}] =
//     queryKey
//   const { data } = await axiosInstance.get(url, {
//     params: { per_page, page, ...queryParams },
//   })
//   return data
// }

// export function useGetAllCollection(
//   url,
//   { queryParams = {}, ...settings } = {}
// ) {
//   const [hasAllData, setHasAllData] = useState(false)

//   const {
//     hasNextPage,
//     isFetchingNextPage,
//     isFetching,
//     fetchNextPage,
//     data,
//     ...rest
//   } = useInfiniteQuery([url, queryParams], infiniteCollectionQueryFn, {
//     getNextPageParam: (lastPage) => {
//       // console.log({ lastPage })
//       return lastPage?.pagination?.nextPage || false
//     },
//     getPreviousPageParam: (firstPage) => {
//       // console.log({ firstPage })
//       return firstPage?.pagination?.prevPage || false
//     },
//     ...settings,
//   })

//   useEffect(() => {
//     if (!hasNextPage && !isFetchingNextPage && !isFetching) {
//       setHasAllData(true)
//     } else if (!isFetchingNextPage && hasNextPage) {
//       console.log('fetching next page')
//       fetchNextPage()
//     }
//   }, [hasNextPage, isFetchingNextPage, isFetching, fetchNextPage])

//   const allData = useMemo(() => {
//     if (!data) {
//       return null
//     }

//     return data.pages.reduce((arr, { data }) => [...arr, ...data], [])
//   }, [data])

//   return {
//     hasAllData,
//     allData,
//     hasNextPage,
//     isFetchingNextPage,
//     isFetching,
//     fetchNextPage,
//     ...rest,
//   }
// }

export class FetchError extends Error {
  constructor(message, response, data) {
    super(message)
    this.response = response
    this.data = data
    this.status = response?.status
  }
}

export function useMutateInstance(
  url,
  {
    queryParams = {},
    method,
    onSuccess,
    requestSettings = {},
    updateSelf = true,
    transformBody = (data) => data,
    ...settings
  } = {}
) {
  const defaultMutateFn = useCallback(
    async (mutationData) => {
      try {
        const { data } = await axiosInstance.request({
          params: queryParams,
          url,
          method,
          data: transformBody(mutationData),
          ...requestSettings,
        })
        return data
      } catch (error) {
        if (error.response && error.response.data) {
          throw new FetchError(
            error.response.data.message,
            error.response,
            error.response.data
          )
        }

        console.log(error)

        throw new FetchError('There was a server error. (json)', error.response)
      }
    },
    [queryParams, url, method, requestSettings, transformBody]
  )

  // have api return
  // links: {self:'url',serviceLab:'url'}
  // invalidations: [url,url,url]

  const queryClient = useQueryClient()

  function wrappedOnSuccess(data, variables, context) {
    if (updateSelf && data?.data?.apiLinks?.self) {
      queryClient.setQueryData([data.data.apiLinks.self], data)
    }

    if (data?.invalidations?.length) {
      data.invalidations.forEach((invalidation) => {
        if (typeof invalidation === 'string') {
          queryClient.invalidateQueries([invalidation], {
            refetchInactive: true,
          })
        } else {
          queryClient.invalidateQueries(invalidation[0], invalidation[1])
        }
      })
    }

    if (onSuccess) {
      onSuccess(data, variables, context)
    }
  }

  return useMutation(defaultMutateFn, {
    mutationKey: url,
    onSuccess: wrappedOnSuccess,
    ...settings,
  })
}
